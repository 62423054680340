import { Slots } from "../../../types";

export default (from: Date, to: Date, intervalSize: number) => {
  const chechIfAllowed = (date: Date) => {
    const now = new Date(Date.now() - date.getTimezoneOffset() * 60000);
    let allowed = false;

    if (date > now) {
      allowed = true;
    }
    return allowed;
  };

  const result: Slots = {
    intervalSize,
    availableIntervals: {},
  };
  const diffHours = Math.abs(from.getTime() - to.getTime()) / 36e5;

  let tmpDate = new Date(from.getTime() - from.getTimezoneOffset() * 60000);
  result.availableIntervals[tmpDate.toISOString().substring(11, 16)] =
    chechIfAllowed(tmpDate);
  for (let i = 1; i < diffHours * (60 / intervalSize); i++) {
    tmpDate = new Date(tmpDate.getTime() + intervalSize * 60000);

    result.availableIntervals[tmpDate.toISOString().substring(11, 16)] =
      chechIfAllowed(tmpDate);
  }
  return result;
};
