
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { usePermissions } from "../composable/usePermissions";
import { useStore } from "../store";
import { actions } from "../utils/const";

export const FooterComponent = defineComponent({
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const { hasPermission } = usePermissions();

    const regulationUrl = computed(() =>
      store.state.runtimeConfig.type === "WELBI"
        ? "./regulation-welbi.pdf"
        : "/regulation"
    );

    const tagetRegulationUrl = computed(() =>
      store.state.runtimeConfig.type === "WELBI" ? "_blank" : "_self"
    );

    return {
      regulationUrl,
      tagetRegulationUrl,
      hasPermission,
      actions,
      t,
    };
  },
});

export default FooterComponent;
