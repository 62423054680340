import { Language, LanguagesRegistration } from "../../../types";

export const languages: Array<Language> = [
  { id: 2, name: "angielski" },
  { id: 5, name: "niemiecki" },
  { id: 1, name: "polski" },
  { id: 4, name: "rosyjski" },
  { id: 3, name: "ukraiński" },
];

export const languagesRegistration: LanguagesRegistration = {
  DE: 5,
  RU: 4,
  UA: 3,
  EN: 2,
  PL: 1,
};
