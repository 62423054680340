import { AdditionalSlots } from "../../../types";

const additionalSlots: AdditionalSlots = {
  additionalSlots: [
    {
      slot: "CALL-20210225-10-10-1300-#1",
      doctor: { id: 10 },
      start: "2021-02-25T13:00:00",
      end: "2021-02-25T13:10:00",
      price: 59.0,
    },
    {
      slot: "CALL-20210225-17-10-1300-#1",
      doctor: { id: 17 },
      start: "2021-02-25T13:00:00",
      end: "2021-02-25T13:10:00",
      price: 59.0,
    },
  ],
  doctors: {
    "17": {
      id: 17,
      firstName: "Lekarz_17",
      lastName: "Testowy_17",
      specializations: [{ id: 21, name: "" }],
    },
    "10": {
      id: 10,
      firstName: "Lekarz_10",
      lastName: "Testowy_10",
      specializations: [
        { id: 21, name: "" },
        { id: 22, name: "" },
      ],
    },
  },
  specializations: {
    "21": { id: 21, name: "Internistyczna" },
    "22": { id: 22, name: "Pediatryczna" },
  },
  page: 0,
  size: 5,
  totalElements: 2,
};

export default additionalSlots;
