import { Visit } from "../../../types";

const appointmentAt = new Date();
const finishedAt = ((d) => new Date(d.setHours(d.getHours() + 1)))(new Date());
const conversationExpiresAt = ((d) => new Date(d.setDate(d.getDate() + 1)))(
  new Date()
);

const visit: Visit = {
  id: "10247",
  contractorId: "7",
  appointmentAt: appointmentAt.toISOString(),
  finishedAt: finishedAt.toISOString(),
  doctor: "Jan Kowalski",
  processStatus: "FINISHED",
  conversationExpiresAt: conversationExpiresAt.toISOString(),
  visitType: "HOUSE",
  nfzVisit: false,
  specialization: "ALLERGIST",
};

export default visit;
