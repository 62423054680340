import { City } from "../../../types";

const cities: Array<City> = [
  { id: 1, town: "Kraków", inflection: "w Krakowie", slug: "krakow" },
  { id: 2, town: "Łódź", inflection: "w Łodzi", slug: "lodz" },
  { id: 3, town: "Poznań", inflection: "w Poznaniu", slug: "poznan" },
  { id: 4, town: "Gdańsk", inflection: "w Gdańsku", slug: "gdansk" },
  { id: 5, town: "Gdynia", inflection: "w Gdyni", slug: "gdynia" },
  { id: 6, town: "Sopot", inflection: "w Sopocie", slug: "sopot" },
  { id: 7, town: "Warszawa", inflection: "w Warszawie", slug: "warszawa" },
  { id: 8, town: "Wrocław", inflection: "we Wrocławiu", slug: "wroclaw" },
  {
    id: 12,
    town: "Białystok",
    inflection: "w Białymstoku",
    slug: "bialystok",
  },
  { id: 11, town: "Tychy", inflection: "w Tychach", slug: "tychy" },
  { id: 13, town: "Szczecin", inflection: "w Szczecinie", slug: "szczecin" },
  { id: 10, town: "Gliwice", inflection: "w Gliwicach", slug: "gliwice" },
  { id: 9, town: "Piaseczno", inflection: "w Piasecznie", slug: "piaseczno" },
];
export default cities;
