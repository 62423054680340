import { Country } from "../../../types";

const countries: Array<Country> = [
  { id: 4, name: "Afganistan" },
  { id: 8, name: "Albania" },
  { id: 12, name: "Algieria" },
  { id: 20, name: "Andora" },
  { id: 24, name: "Angola" },
  { id: 28, name: "Antigua i Barbuda" },
  { id: 682, name: "Arabia Saudyjska" },
  { id: 32, name: "Argentyna" },
  { id: 51, name: "Armenia" },
  { id: 36, name: "Australia" },
  { id: 40, name: "Austria" },
  { id: 31, name: "Azerbejdżan" },
  { id: 44, name: "Bahamy" },
  { id: 48, name: "Bahrajn" },
  { id: 50, name: "Bangladesz" },
  { id: 52, name: "Barbados" },
  { id: 56, name: "Belgia" },
  { id: 84, name: "Belize" },
  { id: 204, name: "Benin" },
  { id: 64, name: "Bhutan" },
  { id: 112, name: "Białoruś" },
  { id: 68, name: "Boliwia" },
  { id: 70, name: "Bośnia i Hercegowina" },
  { id: 72, name: "Botswana" },
  { id: 76, name: "Brazylia" },
  { id: 96, name: "Brunei" },
  { id: 100, name: "Bułgaria" },
  { id: 854, name: "Burkina Faso" },
  { id: 108, name: "Burundi" },
  { id: 152, name: "Chile" },
  { id: 156, name: "Chiny" },
  { id: 191, name: "Chorwacja" },
  { id: 196, name: "Cypr" },
  { id: 148, name: "Czad" },
  { id: 499, name: "Czarnogóra" },
  { id: 203, name: "Czechy" },
  { id: 208, name: "Dania" },
  { id: 180, name: "Demokratyczna Republika Konga" },
  { id: 212, name: "Dominika" },
  { id: 214, name: "Dominikana" },
  { id: 262, name: "Dżibuti" },
  { id: 818, name: "Egipt" },
  { id: 218, name: "Ekwador" },
  { id: 232, name: "Erytrea" },
  { id: 233, name: "Estonia" },
  { id: 748, name: "Eswatini" },
  { id: 231, name: "Etiopia" },
  { id: 242, name: "Fidżi" },
  { id: 608, name: "Filipiny" },
  { id: 246, name: "Finlandia" },
  { id: 250, name: "Francja" },
  { id: 266, name: "Gabon" },
  { id: 270, name: "Gambia" },
  { id: 288, name: "Ghana" },
  { id: 300, name: "Grecja" },
  { id: 308, name: "Grenada" },
  { id: 268, name: "Gruzja" },
  { id: 328, name: "Gujana" },
  { id: 320, name: "Gwatemala" },
  { id: 324, name: "Gwinea" },
  { id: 624, name: "Gwinea Bissau" },
  { id: 226, name: "Gwinea Równikowa" },
  { id: 332, name: "Haiti" },
  { id: 724, name: "Hiszpania" },
  { id: 528, name: "Holandia" },
  { id: 340, name: "Honduras" },
  { id: 356, name: "Indie" },
  { id: 360, name: "Indonezja" },
  { id: 368, name: "Irak" },
  { id: 364, name: "Iran" },
  { id: 372, name: "Irlandia" },
  { id: 352, name: "Islandia" },
  { id: 376, name: "Izrael" },
  { id: 388, name: "Jamajka" },
  { id: 392, name: "Japonia" },
  { id: 887, name: "Jemen" },
  { id: 400, name: "Jordania" },
  { id: 116, name: "Kambodża" },
  { id: 120, name: "Kamerun" },
  { id: 124, name: "Kanada" },
  { id: 634, name: "Katar" },
  { id: 398, name: "Kazachstan" },
  { id: 404, name: "Kenia" },
  { id: 417, name: "Kirgistan" },
  { id: 296, name: "Kiribati" },
  { id: 170, name: "Kolumbia" },
  { id: 174, name: "Komory" },
  { id: 178, name: "Kongo" },
  { id: 408, name: "Korea Północna" },
  { id: 410, name: "Korea Południowa" },
  { id: 188, name: "Kostaryka" },
  { id: 192, name: "Kuba" },
  { id: 414, name: "Kuwejt" },
  { id: 418, name: "Laos" },
  { id: 426, name: "Lesotho" },
  { id: 422, name: "Liban" },
  { id: 430, name: "Liberia" },
  { id: 434, name: "Libia" },
  { id: 438, name: "Liechtenstein" },
  { id: 440, name: "Litwa" },
  { id: 428, name: "Łotwa" },
  { id: 442, name: "Luksemburg" },
  { id: 807, name: "Macedonia Północna" },
  { id: 450, name: "Madagaskar" },
  { id: 454, name: "Malawi" },
  { id: 462, name: "Malediwy" },
  { id: 458, name: "Malezja" },
  { id: 466, name: "Mali" },
  { id: 470, name: "Malta" },
  { id: 504, name: "Maroko" },
  { id: 478, name: "Mauretania" },
  { id: 480, name: "Mauritius" },
  { id: 484, name: "Meksyk" },
  { id: 583, name: "Mikronezja" },
  { id: 104, name: "Mjanma" },
  { id: 498, name: "Mołdawia" },
  { id: 492, name: "Monako" },
  { id: 496, name: "Mongolia" },
  { id: 508, name: "Mozambik" },
  { id: 516, name: "Namibia" },
  { id: 520, name: "Nauru" },
  { id: 524, name: "Nepal" },
  { id: 276, name: "Niemcy" },
  { id: 562, name: "Niger" },
  { id: 566, name: "Nigeria" },
  { id: 558, name: "Nikaragua" },
  { id: 578, name: "Norwegia" },
  { id: 554, name: "Nowa Zelandia" },
  { id: 512, name: "Oman" },
  { id: 586, name: "Pakistan" },
  { id: 585, name: "Palau" },
  { id: 591, name: "Panama" },
  { id: 598, name: "Papua-Nowa Gwinea" },
  { id: 600, name: "Paragwaj" },
  { id: 604, name: "Peru" },
  { id: 616, name: "Polska" },
  { id: 710, name: "Południowa Afryka" },
  { id: 620, name: "Portugalia" },
  { id: 140, name: "Republika Środkowoafrykańska" },
  { id: 132, name: "Republika Zielonego Przylądka" },
  { id: 643, name: "Rosja" },
  { id: 642, name: "Rumunia" },
  { id: 646, name: "Rwanda" },
  { id: 659, name: "Saint Kitts i Nevis" },
  { id: 662, name: "Saint Lucia" },
  { id: 670, name: "Saint Vincent i Grenadyny" },
  { id: 222, name: "Salwador" },
  { id: 882, name: "Samoa" },
  { id: 674, name: "San Marino" },
  { id: 686, name: "Senegal" },
  { id: 688, name: "Serbia" },
  { id: 690, name: "Seszele" },
  { id: 694, name: "Sierra Leone" },
  { id: 702, name: "Singapur" },
  { id: 703, name: "Słowacja" },
  { id: 705, name: "Słowenia" },
  { id: 706, name: "Somalia" },
  { id: 144, name: "Sri Lanka" },
  { id: 840, name: "Stany Zjednoczone" },
  { id: 729, name: "Sudan" },
  { id: 728, name: "Sudan Południowy" },
  { id: 740, name: "Surinam" },
  { id: 760, name: "Syria" },
  { id: 756, name: "Szwajcaria" },
  { id: 752, name: "Szwecja" },
  { id: 762, name: "Tadżykistan" },
  { id: 764, name: "Tajlandia" },
  { id: 834, name: "Tanzania" },
  { id: 626, name: "Timor Wschodni" },
  { id: 768, name: "Togo" },
  { id: 776, name: "Tonga" },
  { id: 780, name: "Trynidad i Tobago" },
  { id: 788, name: "Tunezja" },
  { id: 792, name: "Turcja" },
  { id: 795, name: "Turkmenistan" },
  { id: 798, name: "Tuvalu" },
  { id: 800, name: "Uganda" },
  { id: 804, name: "Ukraina" },
  { id: 858, name: "Urugwaj" },
  { id: 860, name: "Uzbekistan" },
  { id: 548, name: "Vanuatu" },
  { id: 348, name: "Węgry" },
  { id: 862, name: "Wenezuela" },
  { id: 826, name: "Wielka Brytania" },
  { id: 704, name: "Wietnam" },
  { id: 380, name: "Włochy" },
  { id: 384, name: "Wybrzeże Kości Słoniowej" },
  { id: 584, name: "Wyspy Marshalla" },
  { id: 90, name: "Wyspy Salomona" },
  { id: 678, name: "Wyspy Świętego Tomasza i Książęca" },
  { id: 894, name: "Zambia" },
  { id: 716, name: "Zimbabwe" },
  { id: 784, name: "Zjednoczone Emiraty Arabskie" },
];

export default countries;
