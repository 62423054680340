import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61dd7a3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "consultation consultation__text" }
const _hoisted_2 = { class: "app-header__left" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 0,
  class: "app-header__nav"
}
const _hoisted_5 = {
  key: 1,
  class: "app-header__nav"
}
const _hoisted_6 = { class: "app-header__left" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "app-header__right" }
const _hoisted_9 = {
  key: 0,
  class: "app-header__nav"
}
const _hoisted_10 = { href: "/contact" }
const _hoisted_11 = { href: "/" }
const _hoisted_12 = {
  key: 1,
  class: "app-header__nav"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeftOutlined = _resolveComponent("ArrowLeftOutlined")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_MenuOutlined = _resolveComponent("MenuOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DownOutlined = _resolveComponent("DownOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-header", { 'app-header--consultation': _ctx.route.name === 'Consultation' }])
  }, [
    (_ctx.route.name === 'Consultation')
      ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeRoute('/')))
                }, [
                  _createVNode(_component_ArrowLeftOutlined, { class: "consultation consultation__icon" }),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t("CONSULTATION.HEADER")), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : (_openBlock(), _createBlock(_component_a_row, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              class: "app-header__wrapper app-header__wrapper--mobile",
              xs: 24,
              sm: 0
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { xs: 24 }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_a_button, {
                            class: "app-header__hamburger",
                            type: "link",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visibleMobileMenu = true)),
                            shape: "round"
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MenuOutlined)
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createElementVNode("a", {
                            href: _ctx.authUrl ?? _ctx.siteUrl
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass('app-header__logo app-header__logo--' + _ctx.typeClass)
                            }, null, 2 /* CLASS */)
                          ], 8 /* PROPS */, _hoisted_3),
                          _createVNode(_component_a_drawer, {
                            placement: "left",
                            closable: true,
                            class: "app-header__drawer",
                            visible: _ctx.visibleMobileMenu,
                            "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.visibleMobileMenu) = $event))
                          }, {
                            default: _withCtx(() => [
                              (!_ctx.isLoggedIn)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createElementVNode("nav", null, [
                                      _createVNode(_component_a_button, {
                                        class: "app-header__contact",
                                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeRoute('/contact'))),
                                        type: "link"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t("HEADER.CONTACT")), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      }),
                                      _createVNode(_component_a_button, {
                                        class: _normalizeClass(['app-header__login']),
                                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeRoute('/'))),
                                        type: "link"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t("HEADER.SIGN_IN")), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ])
                                  ]))
                                : _createCommentVNode("v-if", true),
                              (_ctx.isLoggedIn)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createElementVNode("nav", null, [
                                      (_ctx.hasPermission(_ctx.actions.SHOW_VISITS))
                                        ? (_openBlock(), _createBlock(_component_a_button, {
                                            key: 0,
                                            class: "app-header__visits",
                                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeRoute('/'))),
                                            type: "link"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.t("HEADER.MY_VISITS")), 1 /* TEXT */)
                                            ]),
                                            _: 1 /* STABLE */
                                          }))
                                        : _createCommentVNode("v-if", true),
                                      (_ctx.hasPermission(_ctx.actions.SHOW_MAKE_APPOINTMENT))
                                        ? (_openBlock(), _createBlock(_component_a_button, {
                                            key: 1,
                                            class: "app-header__make-appointment",
                                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeRoute('/nfz-visit-type'))),
                                            type: "link"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.t("HEADER.MAKE_APPOINTMENT")), 1 /* TEXT */)
                                            ]),
                                            _: 1 /* STABLE */
                                          }))
                                        : _createCommentVNode("v-if", true),
                                      (_ctx.hasPermission(_ctx.actions.SHOW_PROFILE))
                                        ? (_openBlock(), _createBlock(_component_a_button, {
                                            key: 2,
                                            class: "app-header__profile",
                                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeRoute('/profile'))),
                                            type: "link"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.t("HEADER.ACCOUNT_DATA")), 1 /* TEXT */)
                                            ]),
                                            _: 1 /* STABLE */
                                          }))
                                        : _createCommentVNode("v-if", true),
                                      _createVNode(_component_a_button, {
                                        class: "app-header__logout",
                                        onClick: _ctx.logout,
                                        type: "link"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t("HEADER.SIGN_OUT")), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      }, 8 /* PROPS */, ["onClick"])
                                    ])
                                  ]))
                                : _createCommentVNode("v-if", true)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["visible"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_a_col, {
              class: "app-header__wrapper app-header__wrapper--desktop",
              xs: 0,
              sm: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      lg: 8,
                      md: 8,
                      sm: 6,
                      xs: 10
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("a", {
                            href: _ctx.authUrl ?? _ctx.siteUrl
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass('app-header__logo app-header__logo--' + _ctx.typeClass)
                            }, null, 2 /* CLASS */)
                          ], 8 /* PROPS */, _hoisted_7)
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_a_col, {
                      lg: 16,
                      md: 16,
                      sm: 18,
                      xs: 14
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          (!_ctx.isLoggedIn)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("nav", null, [
                                  _createElementVNode("a", _hoisted_10, [
                                    _createVNode(_component_a_button, { type: "link" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t("HEADER.CONTACT")), 1 /* TEXT */)
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _createElementVNode("a", _hoisted_11, [
                                    _createVNode(_component_a_button, {
                                      size: "small",
                                      class: "app-header__login",
                                      type: "primary"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t("HEADER.SIGN_IN")), 1 /* TEXT */)
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ])
                                ])
                              ]))
                            : _createCommentVNode("v-if", true),
                          (_ctx.isLoggedIn)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _createElementVNode("nav", null, [
                                  (_ctx.hasPermission(_ctx.actions.SHOW_VISITS))
                                    ? (_openBlock(), _createBlock(_component_router_link, {
                                        key: 0,
                                        to: "/"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_button, { type: "link" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.t("HEADER.MY_VISITS")), 1 /* TEXT */)
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      }))
                                    : _createCommentVNode("v-if", true),
                                  (_ctx.hasPermission(_ctx.actions.SHOW_MAKE_APPOINTMENT))
                                    ? (_openBlock(), _createBlock(_component_router_link, {
                                        key: 1,
                                        to: "/nfz-visit-type"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_button, { type: "link" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.t("HEADER.MAKE_APPOINTMENT")), 1 /* TEXT */)
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      }))
                                    : _createCommentVNode("v-if", true),
                                  (_ctx.hasPermission(_ctx.actions.SHOW_PROFILE))
                                    ? (_openBlock(), _createBlock(_component_a_dropdown, {
                                        key: 2,
                                        class: "app-header__menu",
                                        trigger: ['click']
                                      }, {
                                        overlay: _withCtx(() => [
                                          _createVNode(_component_a_menu, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_a_menu_item, { key: "0" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_router_link, { to: "/profile" }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("a", null, _toDisplayString(_ctx.t("HEADER.ACCOUNT_DATA")), 1 /* TEXT */)
                                                    ]),
                                                    _: 1 /* STABLE */
                                                  })
                                                ]),
                                                _: 1 /* STABLE */
                                              }),
                                              _createVNode(_component_a_menu_item, { key: "1" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("a", {
                                                    class: "app-header__logout",
                                                    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                                                  }, _toDisplayString(_ctx.t("HEADER.SIGN_OUT")), 1 /* TEXT */)
                                                ]),
                                                _: 1 /* STABLE */
                                              })
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        default: _withCtx(() => [
                                          _createElementVNode("a", {
                                            class: "ant-dropdown-link",
                                            onClick: _cache[8] || (_cache[8] = (e) => e.preventDefault())
                                          }, [
                                            _createTextVNode(_toDisplayString(_ctx.t("HEADER.PROFILE")) + " ", 1 /* TEXT */),
                                            _createVNode(_component_DownOutlined)
                                          ])
                                        ]),
                                        _: 1 /* STABLE */
                                      }))
                                    : _createCommentVNode("v-if", true),
                                  (!_ctx.hasPermission(_ctx.actions.SHOW_PROFILE))
                                    ? (_openBlock(), _createBlock(_component_a_button, {
                                        key: 3,
                                        onClick: _ctx.logout,
                                        class: "app-header__logout",
                                        type: "link"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t("HEADER.SIGN_OUT")), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      }, 8 /* PROPS */, ["onClick"]))
                                    : _createCommentVNode("v-if", true)
                                ])
                              ]))
                            : _createCommentVNode("v-if", true)
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
  ], 2 /* CLASS */))
}