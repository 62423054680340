import { Appointment } from "../../../types";

export const APPOINTMENT_HOUSE: Appointment = {
  id: 94537,
};
export const APPOINTMENT_CALL: Appointment = {
  id: 23433,
};
export const APPOINTMENT_CALL_2: Appointment = {
  id: 34598,
};
export const APPOINTMENT_HOUSE_CASH: Appointment = {
  id: 52537,
};
