import { Specialization } from "../../../types";

export default (type: string): Array<Specialization> => {
  let res: Array<Specialization> = [];
  switch (type) {
    case "CALL": {
      res = [
        { id: 9, name: "Alergologiczna" },
        { id: 37, name: "Alergologiczna dziecięca" },
        { id: 33, name: "Chirurgia dziecięca" },
        { id: 4, name: "Chirurgiczna" },
        { id: 38, name: "Chorób płuc dziecięca" },
        { id: 8, name: "Dermatologiczna" },
        { id: 40, name: "Dermatologiczna dziecięca" },
        { id: 11, name: "Diabetologiczna" },
        { id: 41, name: "Diabetologiczna dziecięca" },
        { id: 6, name: "Dietetyczna" },
        { id: 32, name: "Dietetyczna dziecięca" },
        { id: 1, name: "Endokrylogiczna" },
        { id: 34, name: "Endokrynologia dziecięca" },
        { id: 16, name: "Gastrologiczna" },
        { id: 42, name: "Gastrologiczna dziecięca" },
        { id: 24, name: "Geriatryczna" },
        { id: 2, name: "Ginekologiczna" },
        { id: 13, name: "Kardiologiczna" },
        { id: 43, name: "Kardiologiczna dziecięca" },
        { id: 14, name: "Laryngologiczna" },
        { id: 25, name: "Laryngologiczna dziecięca" },
        { id: 39, name: "Medycyna podróży dziecięca" },
        { id: 10, name: "Medycyny podróży" },
        { id: 19, name: "Nefrologiczna" },
        { id: 26, name: "Nefrologiczna dziecięca" },
        { id: 17, name: "Neurologiczna" },
        { id: 27, name: "Neurologiczna dziecięca" },
        { id: 12, name: "Onkologiczna" },
        { id: 28, name: "Onkologiczna dziecięca" },
        { id: 36, name: "Ortopedia dziecięca" },
        { id: 3, name: "Ortopedyczna" },
        { id: 7, name: "Położnej" },
        { id: 5, name: "Psychologiczna" },
        { id: 29, name: "Psychologiczna dziecięca" },
        { id: 44, name: "Psychiatra" },
        { id: 45, name: "Psychiatra dziecięcy" },
        { id: 15, name: "Pulmonologiczna" },
        { id: 20, name: "Reumatologiczna" },
        { id: 30, name: "Reumatologiczna dziecięca" },
        { id: 18, name: "Urologiczna" },
        { id: 31, name: "Urologiczna dziecięca" },
      ];
      break;
    }
    case "HOUSE": {
      res = [{ id: 0, name: "Internista" }];
    }
  }
  return res;
};
