import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40ab164b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-footer" }
const _hoisted_2 = { class: "app-footer__nav" }
const _hoisted_3 = ["target", "href"]
const _hoisted_4 = { class: "app-footer__logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, {
      type: "flex",
      align: "middle"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          xs: 24,
          sm: 12
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("nav", null, [
                _createElementVNode("a", {
                  target: _ctx.tagetRegulationUrl,
                  href: _ctx.regulationUrl
                }, [
                  _createVNode(_component_a_button, { type: "link" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("FOOTER.REGULATION")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ], 8 /* PROPS */, _hoisted_3)
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }),
        (_ctx.hasPermission(_ctx.actions.SHOW_POWEREDBY))
          ? (_openBlock(), _createBlock(_component_a_col, {
              key: 0,
              xs: 0,
              sm: 12
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("FOOTER.POWERED_BY")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}