import { Order, PaymentType, Price } from "../../../types";
import { getOrder } from "./order";

export default (
  slotReservationId: Order["id"],
  voucher: string,
  paymentType: PaymentType
) => {
  let basePrice = 0.0;
  switch (slotReservationId) {
    case getOrder("ORDER_CALL").id:
    case getOrder("ORDER_CALL_2").id: {
      basePrice = 150.0;
      break;
    }
    case getOrder("ORDER_HOUSE").id: {
      basePrice = 249.0;
      break;
    }
  }
  const price: Price = {
    basePrice,
    discount: 0,
    cashPaymentCost: 0,
    price: basePrice,
    voucherStatus: "ACTIVE",
  };
  if (voucher === "TEST") {
    price.discount = 20.0;
    price.price = price.price - price.discount;
  } else if (voucher.length > 0) {
    price.voucherStatus = "INVALID";
  }

  if (paymentType === "CASH") {
    price.cashPaymentCost = 20.0;
    price.price += 20;
  }
  return price;
};
