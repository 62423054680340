import { Order } from "../../../types";

export const getOrder = (name: string): Order => {
  const startDate = new Date();
  startDate.setHours(startDate.getHours() + 1, 0, 0, 0);

  const endDate = new Date();
  endDate.setHours(endDate.getHours() + 1, 30, 0, 0);

  switch (name) {
    case "ORDER_CALL": {
      return {
        id: 32465,
        doctor: {
          id: 6,
          firstName: "Lekarz_6",
          lastName: "Testowy_6",
          specializations: [{ id: 21, name: "Internistyczna" }],
        },
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        price: 59,
        specializationId: 21,
        country: { id: 4, name: "Afganistan" },
        adultsCount: 1,
        childrenCount: 0,
        appointmentType: "CALL",
        forNfz: true,
      };
    }
    case "ORDER_CALL_2": {
      return {
        id: 32462,
        doctor: {
          id: 5,
          firstName: "Lekarz_5",
          lastName: "Testowy_5",
          specializations: [{ id: 21, name: "Internistyczna" }],
        },
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        price: 59,
        specializationId: 21,
        country: { id: 4, name: "Afganistan" },
        adultsCount: 1,
        childrenCount: 0,
        appointmentType: "CALL",
        forNfz: true,
      };
    }
    case "ORDER_HOUSE": {
      return {
        id: 44375,
        doctor: {
          id: 5,
          firstName: "Lekarz_5",
          lastName: "Testowy_5",
          specializations: [{ id: 21, name: "Internistyczna" }],
        },
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        price: 0,
        specializationId: 21,
        country: { id: 4, name: "Afganistan" },
        city: "Warszawa",
        adultsCount: 1,
        childrenCount: 1,
        appointmentType: "HOUSE",
        forNfz: true,
      };
    }
    default: {
      return {
        id: 32465,
        doctor: {
          id: 6,
          firstName: "Lekarz_6",
          lastName: "Testowy_6",
          specializations: [{ id: 21, name: "Internistyczna" }],
        },
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        price: 59,
        specializationId: 21,
        country: { id: 4, name: "Afganistan" },
        adultsCount: 1,
        childrenCount: 0,
        appointmentType: "CALL",
        forNfz: true,
      };
    }
  }
};
