import { createServer, Response } from "@hd2/miragejs";
import visits from "./data/visits";
import visit from "./data/visit";
import documents from "./data/documents";
import document from "./data/document";
import documentTypes from "./data/documentTypes";
import genderTypes from "./data/genderTypes";
import profile from "./data/profile";
import { otheUserPatient, userDoctor, userPatient } from "./data/user";
import conversation from "./data/conversation";
import specializations from "./data/specializations";
import cities from "./data/cities";
import countries from "./data/countries";
import { languages, languagesRegistration } from "./data/languages";
import getSlots from "./data/slots";
import { getOrder } from "./data/order";
import additionalSlots from "./data/additionalSlots";
import calcPrice from "./data/price";
import patients from "./data/patients";
import {
  APPOINTMENT_HOUSE,
  APPOINTMENT_HOUSE_CASH,
  APPOINTMENT_CALL,
  APPOINTMENT_CALL_2,
} from "./data/appointments";

import { AdditionalSlot, AdditionalSlots } from "../../types";

import { orderBy } from "lodash";
import { ResponseList, Visit, VisitDocument } from "../../types";

interface Sort {
  field: string;
  order: "asc" | "desc";
}

export async function makeServer({
  environment = "development",
  apiUrl = "/",
  authUrl = "",
  kcAuthUrl = "",
} = {}) {
  let chatIterator = 1;
  let orderIterator = 1;
  let checkPaymentIterator = 1;
  let refreshToken = true;
  let documentIteractor = 1;
  const server = createServer({
    environment,
    timing: 1000,
    async: environment === "test" ? false : true,
    routes() {
      this.urlPrefix = apiUrl;
      this.pretender.get("data:**", this.pretender.passthrough);
      if (authUrl) {
        this.passthrough(authUrl + "/**");
      }
      if (kcAuthUrl) {
        this.passthrough(kcAuthUrl + "/**");
      }
      this.namespace = "";
      this.post("patient-portal/api/sms-login", () => {
        const headers = {};
        return new Response(200, headers);
      });

      this.post("patient-portal/api/sms-login/verify", () => {
        const headers = {};
        return new Response(200, headers, userPatient);
      });

      this.get("patient-portal/api/appointments", (schema, request) => {
        const page = Number(request.queryParams.page) || 0;
        const pageSize = Number(request.queryParams.pageSize) || 10;

        const headers = {};
        const res: ResponseList<Visit> = { ...visits };

        if (request.queryParams.sort) {
          const sort: Sort = {
            field: "",
            order: "asc",
          };
          sort.field = request.queryParams.sort.split(",")[0];
          switch (request.queryParams.sort.split(",")[1]) {
            case "ASC": {
              sort.order = "asc";
              break;
            }
            case "DESC": {
              sort.order = "desc";
              break;
            }
          }

          res.content = orderBy(
            res.content,
            [
              function (o: Visit) {
                switch (sort.field) {
                  case "processStatus": {
                    return o.processStatus;
                  }
                  case "appointmentAt": {
                    return new Date(o.appointmentAt);
                  }
                  case "doctor": {
                    if (o.doctor) {
                      return o.doctor;
                    }
                    break;
                  }
                  default: {
                    return o.processStatus;
                  }
                }
              },
            ],
            [sort.order]
          );
        }

        res.content = res.content.slice(
          page * pageSize,
          page * pageSize + pageSize
        );
        res.pageable.pageNumber = page;
        res.size = pageSize;

        return new Response(200, headers, res);
      });

      this.get("patient-portal/api/patient-profile", () => {
        const headers = {};
        return new Response(200, headers, profile);
      });

      this.put("patient-portal/api/patient-profile", () => {
        const headers = {};
        return new Response(200, headers, profile);
      });

      this.get("/patient-portal/api/dictionary/document-types", () => {
        const headers = {};
        return new Response(200, headers, documentTypes);
      });

      this.get("/patient-portal/api/dictionary/genders", () => {
        const headers = {};
        return new Response(200, headers, genderTypes);
      });

      this.get("patient-portal/api/conversation/:id", () => {
        const headers = {};
        chatIterator++;
        return new Response(200, headers, chatIterator % 2 ? conversation : []);
      });

      this.get("/patient-portal/api/appointments/:id/patients", () => {
        const headers = {};
        return new Response(200, headers, patients);
      });

      this.post(
        "patient-portal/api/conversation/message/:id",
        (schema, request) => {
          const headers = {};
          const reqBody = JSON.parse(request.requestBody);
          let response = new Response(500, headers);
          if (environment === "test" && reqBody.message === "test") {
            response = new Response(400, headers);
          } else {
            response = new Response(200, headers);
          }
          return response;
        }
      );

      this.get("patient-portal/api/appointments/:id", (schema, request) => {
        const headers = {};
        let response = new Response(500, headers);
        if (request.params.id === "test" && refreshToken) {
          response = new Response(401, headers, {});
          refreshToken = false;
        } else {
          response = new Response(200, headers, visit);
        }
        return response;
      });

      this.get("patient-portal/api/documentation/:id", (schema, request) => {
        const page = Number(request.queryParams.page) || 0;
        const pageSize = Number(request.queryParams.pageSize) || 10;
        const headers = {};

        const res: ResponseList<VisitDocument> = { ...documents };

        if (request.queryParams.sort) {
          const sort: Sort = {
            field: "",
            order: "asc",
          };
          sort.field = request.queryParams.sort.split(",")[0];
          switch (request.queryParams.sort.split(",")[1]) {
            case "ASC": {
              sort.order = "asc";
              break;
            }
            case "DESC": {
              sort.order = "desc";
              break;
            }
          }

          res.content = orderBy(
            res.content,
            [
              function (o: VisitDocument) {
                switch (sort.field) {
                  case "fileName": {
                    return o.originalFileName;
                  }
                  case "uploadedAt": {
                    return new Date(o.uploadedAt);
                  }
                  case "uploadedBy": {
                    return o.uploadedBy;
                  }
                  default: {
                    return new Date(o.uploadedAt);
                  }
                }
              },
            ],
            [sort.order]
          );
        }

        res.content = res.content.slice(
          page * pageSize,
          page * pageSize + pageSize
        );
        res.pageable.pageNumber = page;
        res.size = pageSize;

        return new Response(200, headers, res);
      });

      this.post("patient-portal/api/documentation/:appointmentId/:id", () => {
        const headers = {};
        let response = new Response(500, headers);
        documentIteractor++;
        if (documentIteractor % 2) {
          response = new Response(413, headers);
        } else {
          response = new Response(200, headers, "ok");
        }
        return response;
      });

      this.get("patient-portal/api/documentation/download/:id", () => {
        const headers = {
          "Content-Type": "arraybuffer",
          "Content-Disposition": 'attachment; filename="test-file.jpg"',
        };
        return new Response(200, headers, JSON.stringify(document));
      });

      this.post("patient-portal/api/token/logout", () => {
        const headers = {};
        return new Response(204, headers);
      });

      this.put("patient-portal/api/agreements", () => {
        const headers = {};
        return new Response(200, headers, true);
      });

      this.get("patient-portal/api/token/:id/:token_id", () => {
        const headers = {};
        return new Response(200, headers, userDoctor);
      });

      this.post("patient-portal/api/oauth2/token", () => {
        const headers = {};
        return new Response(200, headers, otheUserPatient);
      });

      this.post("patient-portal/api/token/refresh", (schema, request) => {
        const headers = {};
        const reqBody = JSON.parse(request.requestBody);
        let response = new Response(500, headers);
        if (reqBody.refreshToken[0] === "p") {
          response = new Response(200, headers, userPatient);
        } else if (reqBody.refreshToken[0] === "p") {
          response = new Response(200, headers, userDoctor);
        } else {
          response = new Response(401, headers, {});
        }

        return response;
      });

      this.post("patient-portal/api/contact/message", () => {
        const headers = {};
        return new Response(204, headers);
      });

      this.get("patient-portal/api/notification", () => {
        const headers = {};
        return new Response(200, headers, []);
      });
      this.put("patient-portal/api/notification/deactivate", () => {
        const headers = {};
        return new Response(200, headers);
      });
      this.get("visit-api/v1/specializations", (schema, request) => {
        const type = request.queryParams.type || "CALL";
        const headers = {};
        return new Response(200, headers, specializations(type));
      });

      this.post("visit-api/v1/slot-intervals", (schema, request) => {
        const reqBody = JSON.parse(request.requestBody);
        let from = new Date(`${reqBody.day}T${reqBody.from}Z`);
        from = new Date(from.getTime() + from.getTimezoneOffset() * 60000);
        let to = new Date(`${reqBody.day}T${reqBody.to}Z`);
        to = new Date(to.getTime() + to.getTimezoneOffset() * 60000);
        const intervalSize = Number(reqBody.intervalSize) || 30;
        const headers = {};
        return new Response(200, headers, getSlots(from, to, intervalSize));
      });

      this.post("patient-portal/api/price/calculate", (schema, request) => {
        const headers = {};
        const reqBody = JSON.parse(request.requestBody);
        return new Response(
          200,
          headers,
          calcPrice(
            reqBody.slotReservationId,
            reqBody.voucher,
            reqBody.paymentType
          )
        );
      });

      this.post("/patient-portal/api/patient-profile", () => {
        const headers = {};
        return new Response(200, headers);
      });

      this.post(
        "patient-portal/api/slot/reserve-first-available",
        (schema, request) => {
          const headers = {};
          const reqBody = JSON.parse(request.requestBody);
          let id;
          orderIterator++;
          let response = new Response(500, headers);
          if (orderIterator % 3) {
            response = new Response(404, headers);
          } else if (!reqBody.specializationId) {
            response = new Response(404, headers);
          } else if (new Date(reqBody.dateTimeTo) < new Date()) {
            response = new Response(404, headers);
          } else {
            switch (reqBody.appointmentType) {
              case "CALL": {
                id = getOrder("ORDER_CALL").id;
                break;
              }
              case "HOUSE": {
                id = getOrder("ORDER_HOUSE").id;
                break;
              }
            }
            response = new Response(200, headers, id);
          }
          return response;
        }
      );

      this.post("patient-portal/api/call-back", () => {
        const headers = {};
        return new Response(200, headers);
      });

      this.get("patient-portal/api/slot", (schema, request) => {
        const headers = {};
        let response = new Response(500, headers);
        switch (Number(request.queryParams.id)) {
          case getOrder("ORDER_CALL").id: {
            response = new Response(200, headers, getOrder("ORDER_CALL"));
            break;
          }
          case getOrder("ORDER_CALL_2").id: {
            response = new Response(200, headers, getOrder("ORDER_CALL_2"));
            break;
            1;
          }
          case getOrder("ORDER_HOUSE").id: {
            response = new Response(200, headers, getOrder("ORDER_HOUSE"));
            break;
          }
        }
        return response;
      });

      this.post("patient-portal/api/appointments", (schema, request) => {
        const headers = {};
        let response = new Response(500, headers);
        const reqBody = JSON.parse(request.requestBody);
        switch (reqBody.slotReservationId) {
          case getOrder("ORDER_CALL").id: {
            response = new Response(200, headers, APPOINTMENT_CALL.id);
            break;
          }
          case getOrder("ORDER_CALL_2").id: {
            response = new Response(200, headers, APPOINTMENT_CALL_2.id);
            break;
          }
          case getOrder("ORDER_HOUSE").id: {
            if (reqBody.paymentType === "CASH") {
              response = new Response(200, headers, APPOINTMENT_HOUSE_CASH.id);
            } else {
              response = new Response(200, headers, APPOINTMENT_HOUSE.id);
            }

            break;
          }
        }
        return response;
      });

      this.get("patient-portal/api/slot/additional", (schema, request) => {
        const page = Number(request.queryParams.page) || 0;
        const pageSize = Number(request.queryParams.pageSize) || 10;

        const headers = {};
        const res: AdditionalSlots = { ...additionalSlots };
        if (request.queryParams.sort) {
          const sort: Sort = {
            field: "",
            order: "asc",
          };
          sort.field = request.queryParams.sort.split(",")[0];
          switch (request.queryParams.sort.split(",")[1]) {
            case "ASC": {
              sort.order = "asc";
              break;
            }
            case "DESC": {
              sort.order = "desc";
              break;
            }
          }
          res.additionalSlots = orderBy(
            res.additionalSlots,
            [
              function (o: AdditionalSlot) {
                switch (sort.field) {
                  case "price": {
                    return o.price;
                  }
                  default: {
                    return o.price;
                  }
                }
              },
            ],
            sort.order
          );
        }
        res.additionalSlots = res.additionalSlots.slice(
          page * pageSize,
          page * pageSize + pageSize
        );
        res.page = page;
        res.size = pageSize;

        return new Response(200, headers, res);
      });

      this.get("visit-api/anonymous/towns", () => {
        const headers = {};
        return new Response(200, headers, cities);
      });

      this.get("visit-api/anonymous/document-type", () => {
        const headers = {};
        return new Response(200, headers, documentTypes);
      });

      this.get("visit-api/anonymous/countries", () => {
        const headers = {};
        return new Response(200, headers, countries);
      });

      this.get("visit-api/anonymous/languages", () => {
        const headers = {};
        return new Response(200, headers, languages);
      });

      this.get("/patient-portal/api/dictionary/languages", () => {
        const headers = {};
        return new Response(200, headers, languagesRegistration);
      });

      this.get("/patient-portal/api/payment/redirect", (schema, request) => {
        const headers = {};
        return new Response(200, headers, {
          redirectUrl: `/appointment/${request.queryParams.appointmentId}/result`,
          redirectParams: {},
        });
      });
      this.get(
        "/patient-portal/api/payment/check-status",
        (schema, request) => {
          const headers = {};
          let response = new Response(500, headers);
          const appointmentId = Number(request.queryParams.appointmentId);
          if (checkPaymentIterator > 3) {
            if (appointmentId === APPOINTMENT_CALL_2.id) {
              response = new Response(200, headers, "REJECTED");
            } else {
              response = new Response(200, headers, "ACCEPTED");
            }
          } else {
            response = new Response(200, headers, "PROCESSING");
            checkPaymentIterator++;
          }
          return response;
        }
      );

      this.post("patient-portal/api/slot/extend", () => {
        const headers = {};
        return new Response(200, headers, true);
      });

      this.post("patient-portal/api/slot/reserve", () => {
        const headers = {};
        return new Response(200, headers, getOrder("ORDER_CALL_2").id);
      });
      this.post(
        "/patient-portal/api/doctor-documentation/:visitId/:patientId/generate",
        () => {
          const headers = {};
          return new Response(200, headers, true);
        }
      );
      this.get(
        "/patient-portal/api/doctor-documentation/:visitId/:patientId",
        () => {
          const headers = {};
          return new Response(
            200,
            headers,
            new Blob(document, { type: "application/pdf" })
          );
        }
      );
      this.post(
        "patient-portal/api/patient-profile/disable-popup/make-declaration",
        () => {
          const headers = {};
          return new Response(200, headers);
        }
      );
      this.post(
        "patient-portal/api/patient-profile/disable-popup/change-declaration-falicity",
        () => {
          const headers = {};
          return new Response(200, headers);
        }
      );
      this.post(
        "patient-portal/api/patient-profile/disable-popup/show-subscription-end",
        () => {
          const headers = {};
          return new Response(200, headers);
        }
      );
      this.put(
        "patient-portal/api/patient-profile/show-subscription-end-popup",
        () => {
          const headers = {};
          return new Response(200, headers);
        }
      );
    },
  });

  return server;
}
