import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-514aec0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loading" }
const _hoisted_2 = {
  key: 0,
  class: "loading__header"
}
const _hoisted_3 = { class: "loading__spiner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.logoutText && _ctx.logoutText.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            type: "button",
            onClick: _ctx.logout
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.logoutText), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_spin, { indicator: _ctx.indicator }, null, 8 /* PROPS */, ["indicator"])
    ])
  ]))
}