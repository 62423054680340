import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_maintenance = _resolveComponent("maintenance")!
  const _component_loading = _resolveComponent("loading")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: _ctx.plPL }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.appTypeClass(), 'app-style'])
      }, [
        (!_ctx.loading && !_ctx.maintenance)
          ? (_openBlock(), _createBlock(_component_a_layout_header, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_app_header)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_a_layout_content, {
          class: _normalizeClass([
          { 'ant-layout-content--maintenance': _ctx.maintenance },
          { 'ant-layout-content--loading': !_ctx.maintenance && _ctx.loading },
        ])
        }, {
          default: _withCtx(() => [
            (!_ctx.loading && !_ctx.maintenance)
              ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
              : _createCommentVNode("v-if", true),
            (_ctx.maintenance)
              ? (_openBlock(), _createBlock(_component_maintenance, {
                  key: 1,
                  title: _ctx.t('MAINTENANCE.TITLE'),
                  description: _ctx.t('MAINTENANCE.DESCRIPTION')
                }, null, 8 /* PROPS */, ["title", "description"]))
              : _createCommentVNode("v-if", true),
            (_ctx.loading && !_ctx.maintenance)
              ? (_openBlock(), _createBlock(_component_loading, { key: 2 }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"]),
        (!_ctx.loading && !_ctx.maintenance && _ctx.routerPath !== 'Consultation')
          ? (_openBlock(), _createBlock(_component_a_layout_footer, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_app_footer)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]))
}