import { ResponseList, VisitDocument } from "../../../types";

const documents: ResponseList<VisitDocument> = {
  content: [
    {
      id: "129673223",
      uploadedAt: "2020-10-06T12:49:47.955Z",
      url: "patient-portal/api/documentation/download/129673223",
      uploadedBy: "Jan Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "dlksdfljkfdskjs",
      contentType: "image/png",
      patientName: "Jan Kowalski",
    },
    {
      id: "129623213",
      uploadedAt: "2020-10-06T11:49:47.955Z",
      url: "patient-portal/api/documentation/download/129623213",
      uploadedBy: "Błażej Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "sdffdsoseroiyusef",
      contentType: "image/png",
      patientName: "Malwina Kowalski",
    },
    {
      id: "12968223",
      uploadedAt: "2020-10-06T10:49:47.955Z",
      url: "patient-portal/api/documentation/download/12968223",
      uploadedBy: "Konrad Kowalski",
      uploaderRole: "PATIENT",
      originalFileName: "sdgidsghijodspogdpsg",
      contentType: "image/png",
      patientName: "Genowefa Kowalska",
    },
    {
      id: "122378223",
      uploadedAt: "2020-10-04T10:49:47.955Z",
      url: "patient-portal/api/documentation/download/122378223",
      uploadedBy: "Jacek Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "sdfgldsjfdsgds",
      contentType: "image/png",
      patientName: "Błażej Kowalski",
    },
    {
      id: "12853423",
      uploadedAt: "2020-10-01T10:49:47.955Z",
      url: "patient-portal/api/documentation/download/12853423",
      uploadedBy: "Konrad Kowalski",
      uploaderRole: "PATIENT",
      originalFileName: "sdfjshjdsgfsfg",
      contentType: "image/png",
      patientName: "Konrad Kowalski",
    },
    {
      id: "1745899223",
      uploadedAt: "2020-10-02T10:49:47.955Z",
      url: "patient-portal/api/documentation/download/1745899223",
      uploadedBy: "Zbigniew Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "sdf;lsdg;ldsg;sd",
      contentType: "image/png",
      patientName: "Jan Kowalski",
    },
    {
      id: "123456333",
      uploadedAt: "2020-09-28T10:49:47.955Z",
      url: "patient-portal/api/documentation/download/123456333",
      uploadedBy: "Jacek Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "dsglsdljfsdf",
      contentType: "image/png",
      patientName: "Jan Kowalski",
    },
    {
      id: "17887523",
      uploadedAt: "2020-09-29T10:49:47.955Z",
      url: "patient-portal/api/documentation/download/17887523",
      uploadedBy: "Jacek Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "fgdslfdsalfds",
      contentType: "image/png",
      patientName: "Jan Kowalski",
    },
    {
      id: "1234767823",
      uploadedAt: "2020-09-11T10:29:47.955Z",
      url: "patient-portal/api/documentation/download/1234767823",
      uploadedBy: "Radosław Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "sfdsfsdsdfsfds",
      contentType: "image/png",
      patientName: "Jan Kowalski",
    },
    {
      id: "17622436543",
      uploadedAt: "2020-09-23T16:49:47.955Z",
      url: "patient-portal/api/documentation/download/17622436543",
      uploadedBy: "Filip Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "sdfsdfdsfdsgsdgsdgds",
      contentType: "image/png",
      patientName: "Jan Kowalski",
    },
    {
      id: "89236542",
      uploadedAt: "2020-09-16T12:49:47.955Z",
      url: "patient-portal/api/documentation/download/89236542",
      uploadedBy: "Konrad Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "sfgsdfdsfdsf",
      contentType: "image/png",
      patientName: "Jan Kowalski",
    },
    {
      id: "111118223",
      uploadedAt: "2020-09-23T10:49:47.955Z",
      url: "patient-portal/api/documentation/download/111118223",
      uploadedBy: "Piotr Kowalski",
      uploaderRole: "DOCTOR",
      originalFileName: "sdfdsfdsfdsfsfsdfsd",
      contentType: "image/png",
      patientName: "Jan Kowalski",
    },
  ],
  totalElements: 12,
  size: 10,
  pageable: {
    pageNumber: 0,
  },
};

export default documents;
