import { User } from "../../../types";

/* eslint-disable @typescript-eslint/camelcase */
export const userPatient: User = {
  access_token:
    "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ1eDJmVDhrTFlnSDdLWl9BcVdKUHVQVkhjQUZuNnVkWU53dVJNUEtSLVdVIn0.eyJleHAiOjE2MDI5NTY1NDksImlhdCI6MTYwMjkyMDU0OSwianRpIjoiMjhhOGI5ZjktZWFjMC00MzkyLTlhNjktNmVhMGY3YjI0NjlmIiwiaXNzIjoiaHR0cHM6Ly9hdXRob3JpemF0aW9uLnVhdC5ob21lZG9jdG9yLnBsL2F1dGgvcmVhbG1zL3BwLXB6dSIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiJiY2JjMTE3NC1hNmY2LTRjMmEtYjQyNC04Mjc0YWM3MGVlNzUiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJwcC1wenUtY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6ImI2MmQyNjI5LWI0MDItNDRmMy04MTUyLWM1YmNhY2Q4NzRjZCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9iYWNrZW5kLnVhdC5ob21lZG9jdG9yLnBsIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsIlBBVElFTlQiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6IkFkYW0gQWRhbSIsInByZWZlcnJlZF91c2VybmFtZSI6IjAwMDAwMDI1ODg1IiwiZ2l2ZW5fbmFtZSI6IkFkYW0iLCJmYW1pbHlfbmFtZSI6IkFkYW0ifQ.Qx0Le9jMa1tWIjLGuIRUL6DONj80KDrwt02aXRBTXe2SdqDOH2cN2haF2sjoVUHNZ4QSLbjpdhV7F829A8EWZYXDb9hJVmFPLVrw6nxaA0uXQXqt6e3C09QXPYk31HdTiGf7Z5kKMYtZ5ioNtiFD9XK4o-xPCHu4nOqtaa7ZECBi5UjQoLu8XUc33UpkSq834ScoHYKIZJS1NHjJ3CIWldP0kiS0rx-FM2l1VosYawbCsvmpVuR5DrSz5FX6lDkSrSiavRbOLowfbgZb00_EWZAmYv-TmXEjTGRyrYupCrToEPZ5uK3HoSjHkcexpMgUTF9pVWryGRJEXYgHGdWfWw",
  expires_in: 3000,
  refresh_expires_in: 6000,
  refresh_token:
    "peyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI5YjUyMzBjOC0wZmI3LTQyMjEtODY0ZS0zOWU1MmRlMjJiYTEifQ.eyJleHAiOjE2MDI5MjIzNDksImlhdCI6MTYwMjkyMDU0OSwianRpIjoiYTA1MDA3ZGMtYWY2YS00MDEyLWJiNzItYWEwYWFjNzg1MDQ2IiwiaXNzIjoiaHR0cHM6Ly9hdXRob3JpemF0aW9uLnVhdC5ob21lZG9jdG9yLnBsL2F1dGgvcmVhbG1zL3BwLXB6dSIsImF1ZCI6Imh0dHBzOi8vYXV0aG9yaXphdGlvbi51YXQuaG9tZWRvY3Rvci5wbC9hdXRoL3JlYWxtcy9wcC1wenUiLCJzdWIiOiJiY2JjMTE3NC1hNmY2LTRjMmEtYjQyNC04Mjc0YWM3MGVlNzUiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoicHAtcHp1LWNsaWVudCIsInNlc3Npb25fc3RhdGUiOiJiNjJkMjYyOS1iNDAyLTQ0ZjMtODE1Mi1jNWJjYWNkODc0Y2QiLCJzY29wZSI6InByb2ZpbGUgZW1haWwifQ.Br0Yjs05WpeuoQ7_1uyH-sxNJZ5JA3Fk5qnx1AcoPos",
  token_type: "bearer",
  id_token: null,
  "not-before-policy": 0,
  session_state: "b62d2629-b402-44f3-8152-c5bcacd874cd",
  scope: "profile email",
};

export const userDoctor: User = {
  access_token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MDMyODk4MzMsImlhdCI6MTYwMjkyMDU0OSwianRpIjoiMjhhOGI5ZjktZWFjMC00MzkyLTlhNjktNmVhMGY3YjI0NjlmIiwiaXNzIjoiaHR0cHM6Ly9hdXRob3JpemF0aW9uLnVhdC5ob21lZG9jdG9yLnBsL2F1dGgvcmVhbG1zL3BwLXB6dSIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiJiY2JjMTE3NC1hNmY2LTRjMmEtYjQyNC04Mjc0YWM3MGVlNzUiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJwcC1wenUtY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6ImI2MmQyNjI5LWI0MDItNDRmMy04MTUyLWM1YmNhY2Q4NzRjZCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9iYWNrZW5kLnVhdC5ob21lZG9jdG9yLnBsIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsIkRPQ1RPUiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiQWRhbSBBZGFtIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiMDAwMDAwMjU4ODUiLCJnaXZlbl9uYW1lIjoiQWRhbSIsImZhbWlseV9uYW1lIjoiQWRhbSJ9.3WMKtZOzJOLQfKIHGJi-YafRjd4TlKqanME074Ma9Uk",
  expires_in: 300,
  refresh_expires_in: 600,
  refresh_token:
    "deyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI5YjUyMzBjOC0wZmI3LTQyMjEtODY0ZS0zOWU1MmRlMjJiYTEifQ.eyJleHAiOjE2MDI5MjIzNDksImlhdCI6MTYwMjkyMDU0OSwianRpIjoiYTA1MDA3ZGMtYWY2YS00MDEyLWJiNzItYWEwYWFjNzg1MDQ2IiwiaXNzIjoiaHR0cHM6Ly9hdXRob3JpemF0aW9uLnVhdC5ob21lZG9jdG9yLnBsL2F1dGgvcmVhbG1zL3BwLXB6dSIsImF1ZCI6Imh0dHBzOi8vYXV0aG9yaXphdGlvbi51YXQuaG9tZWRvY3Rvci5wbC9hdXRoL3JlYWxtcy9wcC1wenUiLCJzdWIiOiJiY2JjMTE3NC1hNmY2LTRjMmEtYjQyNC04Mjc0YWM3MGVlNzUiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoicHAtcHp1LWNsaWVudCIsInNlc3Npb25fc3RhdGUiOiJiNjJkMjYyOS1iNDAyLTQ0ZjMtODE1Mi1jNWJjYWNkODc0Y2QiLCJzY29wZSI6InByb2ZpbGUgZW1haWwifQ.Br0Yjs05WpeuoQ7_1uyH-sxNJZ5JA3Fk5qnx1AcoPos",
  token_type: "bearer",
  id_token: null,
  "not-before-policy": 0,
  session_state: "b62d2629-b402-44f3-8152-c5bcacd874cd",
  scope: "profile email",
};

export const otheUserPatient: User = {
  access_token:
    "eyJraWQiOiJPRGJMYUJhdzFuQ3gxcHVwa0o5N2ZjS3JpZU9pZFNGRFF3TGZTMVJMTjhjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJjNGViNTU3MC1kMTAzLTQ3ODgtODMxMy1iYTFiMjBiZDhhMWIiLCJjb2duaXRvOmdyb3VwcyI6WyJVc2VyIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS13ZXN0LTEuYW1hem9uYXdzLmNvbVwvZXUtd2VzdC0xX0hGelNSMXk2RiIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6IjRiY3NjbzQwaWtwaWJlYWR2NzI3NHQ5bmM4Iiwib3JpZ2luX2p0aSI6IjEwMWM5MTZmLWZmMGQtNDY0NC05OTVmLTc5ZTQ3NzI5YzViNiIsImV2ZW50X2lkIjoiNWIwNWYyOTktNWNmYi00ODE5LWE3NTMtZTJhOTY2NzEzNDc2IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJvcGVuaWQiLCJhdXRoX3RpbWUiOjE2MjM5NDIwMDgsImV4cCI6MTYyMzk0NTYwOCwiaWF0IjoxNjIzOTQyMDA4LCJqdGkiOiJmN2NmMWJjZi1lMzRlLTRlMjgtYmIwNS03MjI2YzIzNWQ3M2UiLCJ1c2VybmFtZSI6ImR3aWxkYSJ9.iM9Td4nUryD4P4qiGOZY7rHyPvkSTAK9-JT8wjZmpAmiAJOiMRJoDtJJ8EVDqDhDiOeA9OaYQBsuTjIrIiEktc2R835SIhMfs0xdJCAT3F78vq7xNkxOnB2NFxloRSIIphIOUUnpCyRKVP_sRF-xXdxMDnA3PLUa2B0WE_P4FunMcopA1b8gR6W6OpIrqDQ8hvy6PNfP048VHKYEn_8F5N2RC5QGQg45Kavb8P7ZLdzt_Ro3x0tLBa1KmRE4R1n181tQ_pSLNUpctXaPBynL6eizu-k9w8ZRkm6y9i1Wi_KgMM_MRWMDPrX3f6k00DETUVLjrZ28Qm4TO8avk_FfmA",
  expires_in: 3600,
  id_token:
    "eyJraWQiOiJzUlFhTG1XZExYbHF5ZmRPZ0hBMW1Pb0ltQ2xaaFlObnUwWWpiNmlKcHNrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiQmpGTUI4WXhqSzBLODFWZXpYbllwQSIsInN1YiI6ImRhMGQ3NjM2LWVlYjktNGViNi04NDFjLTZhOGZiMTY4OGJlMSIsImNvZ25pdG86Z3JvdXBzIjpbIlVzZXIiXSwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS13ZXN0LTEuYW1hem9uYXdzLmNvbVwvZXUtd2VzdC0xX0FFWWR3aWllTiIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzp1c2VybmFtZSI6ImhvbWVkb2N0b3IiLCJhdWQiOiI2czF0cTk3NDNiNWhuZnY1MGhyaGZmbm1ibyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjIyNTUxNjU0LCJwaG9uZV9udW1iZXIiOiIrKzMxNjg0ODM3NTg0IiwiZXhwIjoxNjIyNTU1MjU0LCJpYXQiOjE2MjI1NTE2NTQsImVtYWlsIjoiaG9tZWRvY3RvckBleGFtcGxlLmNvbSJ9.UhkOiuoqZB-Xr-ZrG8wP5cqXcDcXqMAvy5WuGDiBGa1cZOrXtWJ_gCOGkXdfAq1XFYE18m6PTiebIV78E2v6UWwtSHZcOIx5Vr0CfGwcGAiHI5C1FU4ogLJjGz1f8rAC7PLO9h9OlCP78QgdIOqdsbdHUpBAio_2_OrStMDgMofiJRFNazETrU7UwePz-9IWMof4zxmJLkQW5SI_KfTBORiOROuHvNuy8p07ZEDJoP3bPWtqcpzEnsaq3pcoZcZXBUaMzoRyNQE6PPrqxELr1FjF8jUMPFspJ-IuLMg9LuNORNOvg7U0fGENNXpl9Emz7tjTZYfbNIDGqQ_sCQYgng",
  refresh_token:
    "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.FIf1g4lij0WdC_m4-LQQxh5GO3STiVw5lqGsZoJSQqoK4qZ8S2DLoCF2fvnmLanAF7ipz_DNxZlqWLGuDvpryOlVs3vmjAX_QZVO5hEVLCMeNgZ2yr8JKQSXBA_o79wmwFkNeoOFEHTAL0efhEK6wHf5zcaCsFPIWxg2w43dwmIVG50AJ4NVHNM8IRPX_AQoM1hS52YtB4lsmvmA9F6fuikA5Ds3HkpQcZ-xPrHUO37zoC0btfksGG-inrGwvkr_2XhFJ36pEUSxwr_OdQEDlw7bMhB9LrBlaiG6qd4HIXJDuBF2ikC4MQN96YGNi9ZpSZ8hXVDtFi7EM1PHn8clqA.cw1OtdKJoNfDPAsi.PV-k972IUpXx3JQN8v63ZvziJ3_vauGGx-RTdoDRQEfXF-zNH8RAUicf8xEFfwH6lUaZNjUs9taAHlNpTREOrqHdEBjMLDFzeeW9Cw7gIB41MOUGi5eA8ZlUMMadt5iwUx9VVmaR8WRkBRP75Kelt3r6oYJPJaFNo5CEPY6woSTIygfrg7zo8a6era_AWD5g2gFwaD6L_qytx4xfrElXBF52aUxxVcOR0A0kAEH0hAKc5GGHrbb9fZdK5GEwkrzUFtxotv2bUgC2XPef-U-ofCIVmD7CgtKaddO1NOHQrVAHYd2IZfKv-9WU9wehSxuEXw93QURbxJeSy1GSOACT1rRXVAcNJxd3MAVxZTDdAoN3Jf5EKywNr1S9ROGpOrbq0vXYNlcZhloufN0aj5l9D9hcdNZZhmwcQJWiFBex3EeSlRDXjvdLlULOr3W4goEr6kbRX-sMUdBrHHcINszZkEIAwVb09eT-aysqGPdbihjcTH_t8-Imt1QA3AddQwsns1_Ap8yh8y98T1qNqHayx3LIe2V_03s6hCsFVvS-9mVtczIrQhagPHHbJIhK3W-aWM1qQakWnJ6xdZft1pG6frOBS_6khN_ZogEo9Ht_rruQeEkaydlU-5B3Ow23KeNCDE68CNKVa9UzXxP1_h7q3_uXctxbB9N_hQalPiIrWX3wSEIkz-K2EhP50RFDv_KOUoIgBDgxpFA6yPWmOqA25A3mqpKmO7o5o4mFyrAcBmIs1q_dA9nj0plr-aTPiR_QujbIZjPxezUZ6MQDSZt8jC0gBZZf9iKbQ1gRO910uXX5AJgPJR1VfoRLqQsYYEfXrwWsjOiXv4owihDLE0zhg1fgR8o7xRA3k9BKRrBhL9Pr2qui2b35_Cf3G4R8KU5MK8avc6yE9VpK4Dr3o1Nl0-vuQa92YZHbgncIZEJsPbw8bCmJAfpwk5TadX2ncVNp6gPHUd4zhuLT8BaWBgHFnft7IMgaTlrF014cSnx2vLDMbi1Vm7612JocgUGGr-JcENnAR4ma8x4TPuYNsuWz9iZ_RjActDzSzMr9Hk6drKrLIhqolWPkb9oJZSft7ncz_zap0KpfWNALSf6jhaj91U-FIPiRSe024lINhIsCes46JUTxlVCV2OaRqUyr7yX-qMKRkHUG7fzkDiHXzNabnYsickl-AJIi.OQ6jZoJCipUYBR3WohxdxA",
  token_type: "Bearer",
};
